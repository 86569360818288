<template>
  <div>
    <div class="fixed  h-16 items-center w-full flex justify-between py-2 px-4 z-50 bg-background backdrop-blur border-b -mb-px top-0">
      <div class="flex gap-10 items-center">
        <div class="h-8 justify-center items-center inline-flex">
          <NuxtLinkLocale
            to="/"
            class="block hover:no-underline active:no-underline"
          >
            <Logo />
          </NuxtLinkLocale>
        </div>
        <div class="flex gap-6 items-center">
          <SlLayoutTopBarNavMenu />
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <SlLayoutTopBarMenuRight />
      </div>
    </div>
  </div>
</template>
