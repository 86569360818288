<script setup lang="ts">
  import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger
  } from '~/modules/ui/components/navigation-menu'

  const user = useSupabaseUser()
  const appConfig = useAppConfig()

  const leftNavLinks = computed(() => {
    return appConfig.navigationRootLinks
  })

const showBetaItems = computed(() => {
  return useIsPlatformAdmin()
  })
</script>

<template>
  <NavigationMenu v-if="user">
    <NavigationMenuList>
      <NavigationMenuItem v-for="(item, i) in leftNavLinks" :key="i">
        <NuxtLink v-if="item.enabled" :to="item.href" active-class="bg-accent">
          <NavigationMenuTrigger
            v-if="item.components " class="bg-background flex items-center gap-2 py-4 px-2 rounded-md" :class="[
              { 'opacity-50': item.beta },
            ]"
          >
            <div v-if="item.icon" class="shrink-0">
              <Icon
                :name="item.icon" size="1.4em" class="-mb-1 text-secondary"
              />
            </div>
            <div v-if="appConfig.navigationSettingsTopBar.showTopLevelTitles" class="text-base font-semibold">
              {{ item.name }}
            </div>
          </NavigationMenuTrigger>
        </NuxtLink>
        <NavigationMenuContent v-if="item.components">
          <ul class="grid w-[300px] gap-3 p-4 md:w-[400px] md:grid-cols-1 lg:w-[400px]">
            <li v-for="component in item.components" :key="component.title">
              <NavigationMenuLink v-if="component.enabled && (showBetaItems || !component.beta)" as-child>
                <NuxtLink
                  :href="component.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-background hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground active:bg-accent active:text-accent-foreground"
                  :class="[
                    { 'opacity-50': component.beta },
                  ]"
                  active-class="bg-accent text-accent-foreground"
                >
                  <div class="flex">
                    <div class="shrink mr-2">
                      <Icon :name="component.icon" size="1.2em" />
                    </div>
                    <div class="grow">
                      <div class="text-md font-medium leading-none">
                        {{ component.title }}
                      </div>
                      <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                        {{ component.description }}
                      </p>
                    </div>
                  </div>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
        <!-- <NavigationMenuContent v-if="item.components">
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
            <li v-for="component in item.components" :key="component.title">
              <NavigationMenuLink as-child>
                <NuxtLink
                  :href="component.href"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-50 hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="flex">
                    <div class="shrink mr-2">
                      <Icon :name="component.icon" size="lg" />
                    </div>
                    <div class="grow">
                      <div class="text-sm font-medium leading-none">
                        {{ component.title }}
                      </div>
                      <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                        {{ component.description }}
                      </p>
                    </div>
                  </div>
                </NuxtLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent> -->
        <NavigationMenuItem v-if="!item.components">
          <NuxtLink :to="item.href" class="flex items-center justify-center gap-2 p-2 rounded-md hover:bg-active" active-class="bg-active">
            <div v-if="item.icon">
              <Icon :name="item.icon" size="size-5" />
            </div>
            <div class="text-base font-semibold">
              {{ item.name }}
            </div>
          </NuxtLink>
        </NavigationMenuItem>
      </navigationmenuitem>
    </NavigationMenuList>
  </NavigationMenu>
</template>
