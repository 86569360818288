<script setup lang="ts">
const isNavOpen = ref(false)

const handleNavToggle = (isOpen: boolean) => {
  isNavOpen.value = isOpen
};

  // Initialize online status tracking
  const userUserOnlineStatus = useUserOnlineStatusStore()

  // onMounted only runs on the client side, so window is available.
  onMounted(() => {
  userUserOnlineStatus.initializeOnlineStatus()
    window.addEventListener('beforeunload', userUserOnlineStatus.cleanupOnlineStatus)
  })
  onBeforeUnmount(() => {
  userUserOnlineStatus.cleanupOnlineStatus()
    window.removeEventListener('beforeunload', userUserOnlineStatus.cleanupOnlineStatus)
  })
</script>

<template>
  <div class="bg-background">
    <SlLayoutTopBar />
    <!-- <SlLayoutSideNav @toggle="handleNavToggle" /> -->
    <div
      class="mt-16 transition-all duration-300 "
      :class="{
        'ml-64': isNavOpen,
        'ml-0': !isNavOpen,
      }"
      style="height: calc(100vh - 4rem); z-index: 30; position: relative;"
    >
      <div class="">
        <NuxtPage />
      </div>
    </div>
    <!-- <div class="">
      <NuxtPage />
    </div> -->
    <BotLoader />
  </div>
</template>
