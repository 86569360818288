<script setup lang="ts">
  import { Bell, Search, Settings } from 'lucide-vue-next'
  import { Label } from '~/modules/ui/components/label'
  import { Switch } from '~/modules/ui/components/switch'
// import { SlAuthUserMenu } from '~/modules/sl/components/auth/'

const user = useSupabaseUser()
</script>

<template>
  <div class="flex justify-between items-center gap-8">
    <div>
      <!-- <div class="flex items-center space-x-2">
        <Label for="privacy-mode">Open</Label>
        <Switch id="privacy-mode" />
        <Label for="privacy-mode">Private</Label>
      </div> -->
    </div>
    <div v-if="user" class="flex items-center gap-3">
      <ConversationsPopoverButton />
      <NotificationsPopoverButton />
      <UserOrganizationSwitcher />
      <SlAuthUserMenu />
    </div>
    <div v-else class="flex items-center gap-3">
      <NuxtLink to="/login">
        <Button variant="outline">
          Sign In
        </Button>
      </NuxtLink>
    </div>
  </div>
</template>
